@import 'src/utils/utils';

.alert-wrapper {
  position: fixed;
  right: 0;
  bottom: 15px;
  z-index: 20;
  display: flex;
  width: 100%;
  max-width: 620px;
  flex-direction: column;
  padding-right: 16px;
  padding-left: 16px;

  @media(min-width: $md) {
    bottom: 60px;
  }
}